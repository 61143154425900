import { render, staticRenderFns } from "./vue-accordion-partial4.vue?vue&type=template&id=98ab1396&scoped=true&"
import script from "./vue-accordion-partial4.vue?vue&type=script&lang=js&"
export * from "./vue-accordion-partial4.vue?vue&type=script&lang=js&"
import style0 from "./vue-accordion-partial4.vue?vue&type=style&index=0&id=98ab1396&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/registry.nlark.com+vue-loader@15.9.8_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98ab1396",
  null
  
)

export default component.exports