<template>
  <li :class="{ active: num == 3 }" @mouseover="active(3)" @mouseleave="active(0)">
    <div style="width: 615px; height: 556px">
      <div class="left">
        <img src="../../../assets/img/icon_gnptys.png" draggable="false" alt="" class="icon" />
        <div class="title">功能配套优势</div>
        <div class="line"></div>
        <div class="info">
          围绕满足人才引进、产业落地、新居民入驻等需求，我区人才公寓、发展大楼、温州医科大学附属第二医院（院区）、温州护士学校、温州外国语学校、温州技师学院等项目均已经建成投用，邻里中心、浙江工贸学院等项目已建成即将投入使用，城市宜居品质正不断提升。
        </div>
        <img src="../../../assets/img/icon_zk.png" draggable="false" alt="" class="arrow" v-if="num != 3" />
      </div>
      <div class="right">
        <div class="title">配套设施</div>
          <img src="../../../assets/img/accordion-partial3.png" draggable="false" alt="" class="img"/>
        <!-- <div class="top"> -->
          <!-- <div class="tip">
            <div class="text">
              简单介绍文字集聚区简单介绍文字集聚区简单介绍文字集聚区简单介绍文字集聚区简单介绍文字集聚区简单介绍文字集聚区简单介绍文字集聚区简单介绍…
            </div>
          </div> -->
        <!-- </div> -->
        <!-- <div class="title">落地项目的展示</div>
        <div class="fl">
          <div class="item" v-for="(item, index) in project" :key="index">
            <img :src="item.src" alt="" draggable="false" />
            <div class="name">{{ item.name }}</div>
          </div>
        </div> -->
      </div>
    </div>
  </li>
</template>
<script>
export default {
  props: ['num'],
  data() {
    return {
      project: [
        { src: require('@/assets/img/img_gnptys2.png'), name: '项目' },
        { src: require('@/assets/img/img_gnptys3.png'), name: '项目' },
        { src: require('@/assets/img/img_gnptys4.png'), name: '项目' },
        { src: require('@/assets/img/img_gnptys5.png'), name: '项目' },
        { src: require('@/assets/img/img_gnptys6.png'), name: '项目' },
        { src: require('@/assets/img/img_gnptys7.png'), name: '项目' },
      ],
    };
  },
  methods: {
    active(num) {
      this.$emit('active', num);
    },
  },
};
</script>
<style lang='scss' scoped>
li {
  width: 160px;
  overflow: hidden;
  float: left;
  transition: all 500ms;
}
.left {
  width: 160px;
  height: 100%;
  float: left;
  transition: all 500ms;
  background-image: linear-gradient(98deg, #3a77af 0%, #135694 99%);
  position: relative;
  .icon {
    margin: 114px auto 16px;
    width: 60px;
    height: 60px;
    display: block;
  }
  .title {
    font-size: 14px;
    color: #ffffff;
    line-height: 19px;
    text-align: center;
    margin-bottom: 8px;
    font-weight: 600;
  }
  .line {
    opacity: 0.36;
    background: #ffffff;
    border-radius: 3px;
    margin: 0 auto;
  }
  .info {
    opacity: 0.8;
    font-size: 12px;
    color: #ffffff;
    line-height: 22px;
    width: 116px;
    margin: 6px auto 0;
  }
  .arrow {
    width: 20px;
    height: 36px;
    position: absolute;
    right: 0;
    top: 260px;
  }
}
.right {
  width: 399px;
  height: 100%;
  float: left;
  padding-top: 22px;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  box-sizing: border-box;
  padding-left: 26px;
  .title {
    font-size: 14px;
    color: #135694;
    font-weight: 600;
    line-height: 16px;
    border-left: 4px solid #135694;
    padding-left: 10px;
    margin-bottom: 17px;
  }
  .img{
    width: 354px;
    display: block;
    margin-top: 55px;
  }
  .top {
    width: 354px;
    height: 160px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
    .tip {
      background: rgba(0, 0, 0, 0.65);
      position: absolute;
      right: 0;
      top: 0;
      width: 166px;
      height: 100%;
      padding: 14px 10px;
      box-sizing: border-box;
      .text {
        opacity: 0.8;
        font-size: 12px;
        color: #ffffff;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
      }
    }
  }
  .fl {
    margin-left: -6px;
    display: flex;
    flex-wrap: wrap;
  }
  .item {
    width: 116px;
    height: 130px;
    padding: 8px 6px 0;
    border: 1px solid #d8d8d8;
    margin-right: 7px;
    box-sizing: border-box;
    margin-bottom: 10px;
    img {
      width: 104px;
      height: 89px;
      display: block;
    }
    .name {
      font-size: 12px;
      color: #333333;
      margin-top: 8px;
      line-height: 16px;
    }
  }
}
</style>
