<template>
  <li :class="{ active: num == 4 }" @mouseover="active(4)" @mouseleave="active(0)">
    <div style="width: 615px; height: 556px">
      <div class="left">
        <img src="../../../assets/img/icon_cyrhfzys.png" draggable="false" alt="" class="icon" />
        <div class="title">产业融合发展优势</div>
        <div class="line"></div>
        <div class="info">瓯江口以智能制造、安全应急现代商贸服务业为主导产业，并按照“北生产、中生态、南生活”的“三生融合”规划，构筑功能布局。随着“三区一园”等重量级国家级战略在瓯江口的相继落地，瓯江口迎来了高速发展的黄金期，产城融合优势进一步显现。</div>
        <img src="../../../assets/img/icon_zk.png" draggable="false" alt="" class="arrow" v-if="num != 4" />
      </div>
      <div class="right">
        <div class="title">三化融合</div>
        <div class="top">
          <img src="../../../assets/img/img_cyrh.png" draggable="false" alt="" />
        </div>
        <div class="title">落地项目的展示</div>
        <div class="fl">
          <div class="item" v-for="(item, index) in project" :key="index">
            <img :src="item.src" alt="" draggable="false" />
            <!-- <div class="name">{{ item.name }}</div> -->
          </div>
        </div>
      </div>
    </div>
  </li>
</template>
<script>
export default {
  props: ['num'],
  data() {
    return {
      project: [
        { src: require('@/assets/img/accordion-partial4 (1).png'), name: '项目' },
        { src: require('@/assets/img/accordion-partial4 (2).png'), name: '项目' },
        { src: require('@/assets/img/accordion-partial4 (3).png'), name: '项目' },
        { src: require('@/assets/img/accordion-partial4 (4).png'), name: '项目' },
        { src: require('@/assets/img/accordion-partial4 (5).png'), name: '项目' },
        { src: require('@/assets/img/accordion-partial4 (6).png'), name: '项目' }
      ],
    };
  },
  methods: {
    active(num) {
      this.$emit('active', num);
    },
  },
};
</script>
<style lang='scss' scoped>
li {
  width: 160px;
  overflow: hidden;
  float: left;
  transition: all 500ms;
}
.left {
  width: 160px;
  height: 100%;
  float: left;
  transition: all 500ms;
  background-image: linear-gradient(98deg, #3a77af 0%, #135694 99%);
  position: relative;
  .icon {
    margin: 114px auto 16px;
    width: 60px;
    height: 60px;
    display: block;
  }
  .title {
    font-size: 14px;
    color: #ffffff;
    line-height: 19px;
    text-align: center;
    margin-bottom: 8px;
    font-weight: 600;
  }
  .line {
    opacity: 0.36;
    background: #ffffff;
    border-radius: 3px;
    margin: 0 auto;
  }
  .info {
    opacity: 0.8;
    font-size: 12px;
    color: #ffffff;
    line-height: 22px;
    width: 116px;
    margin: 6px auto 0;
  }
  .arrow {
    width: 20px;
    height: 36px;
    position: absolute;
    right: 0;
    top: 260px;
  }
}
.right {
  width: 399px;
  height: 100%;
  float: left;
  padding-top: 22px;
  border: 1px solid #d8d8d8;
  border-left: none;
  box-sizing: border-box;
  padding-left: 26px;
  .title {
    font-size: 14px;
    color: #135694;
    font-weight: 600;
    line-height: 16px;
    border-left: 4px solid #135694;
    padding-left: 10px;
    margin-bottom: 17px;
  }
  .top {
    width: 354px;
    height: 160px;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .fl {
    margin-left: -6px;
    display: flex;
    flex-wrap: wrap;
  }
  .item {
    width: 116px;
    height: 130px;
    // padding: 8px 6px 0;
    // border: 1px solid #d8d8d8;
    margin-right: 7px;
    box-sizing: border-box;
    margin-bottom: 10px;
    img {
      width: 100%;
      // height: 89px;
      display: block;
    }
    
  }
}
</style>
