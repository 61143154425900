<template>
  <div class="vue-accordion">
    <ul>
      <partial-accordion1 @active="active" :num="num"></partial-accordion1>
      <partial-accordion2 @active="active" :num="num"></partial-accordion2>
      <partial-accordion3 @active="active" :num="num"></partial-accordion3>
      <partial-accordion4 @active="active" :num="num"></partial-accordion4>
      <img src="../../../assets/img/accordion.png" draggable="false" alt="" class="accordion" :class="'isActive' ? num == 0 : ''" />
    </ul>
  </div>
</template>
<script>
import partialAccordion1 from './vue-accordion-partial1.vue';
import partialAccordion2 from './vue-accordion-partial2.vue';
import partialAccordion3 from './vue-accordion-partial3.vue';
import partialAccordion4 from './vue-accordion-partial4.vue';

export default {
  name: 'vue-accordion',
  components: {
    partialAccordion1,
    partialAccordion2,
    partialAccordion3,
    partialAccordion4,
  },
  data() {
    return {
      num: 0,
    };
  },
  methods: {
    active(num) {
      this.num = num;
    },
  },
};
</script>
<style lang='scss' scoped>
.active {
  width: 615px !important;
  /deep/ .left {
    width: 216px !important;
  }
}
.vue-accordion {
  width: 1200px;
  overflow: hidden;
  height: 556px;
}

.vue-accordion ul {
  width: 100%;
  height: 100%;
}
.accordion {
  float: left;
  // width: 560px;
  height: 556px;
  transition: all 500ms;
  margin-left: 50px;
}
.isActive {
  width: 0;
}
</style>
