<!--
 * @Author: your name
 * @Date: 2020-12-03 08:57:48
 * @LastEditTime: 2020-12-03 19:07:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \enterprise\src\pages\HomePage\index.vue
-->
<template>
  <div>
    <!-- 轮播图 -->
    <div class="block">
      <el-carousel height="51.2vw">
        <el-carousel-item v-for="(item, index) in swiperData" :key="index">
          <img :src="item.url" alt="" draggable="false" width="100%" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <AnchorMenu :menu="anchorMenuData" :offset="80" />
    <!-- 集聚区概况 -->
    <div id="panel1" style="background: #fff">
      <div class="content">
        <div class="title">集聚区概况</div>
        <div class="clearfix">
          <div class="line1-left">
            <div class="content_word">
              瓯江口产业集聚区规划范围，包括洞头的灵昆、霓屿以及新增的城市围垦区，规划面积为130平方公里，规划人口近90万人。规划定位是按照“东方时尚岛.海上新温州”发展战略，依托临近空港与海港的区位优势，着力打造一个空间布局合理、海陆空交通发达、公共配套设施完善、环境优美。战略性新兴产业和现代服务业高度集聚、适宜人居充满都市活力的滨海宜居城区。
            </div>
            <!-- <div class="look_detail">查看详情</div> -->
          </div>
          <div class="line1-right">
            <img src="../../assets/img/img_dzdt.jpg" alt="" />
          </div>
        </div>
        <div class="clearfix">
          <div class="line2-left">
            <img src="../../assets/img/img_bgl.png" alt="" />
          </div>
          <div class="line2-right">
            <ul>
              <li>浙南对外开放桥头堡</li>
              <li>自由贸易联动创新区</li>
              <li>甬台温临港产业基地</li>
              <li>温州海洋经济示范区</li>
              <li>温州都市区发展东拓主平台</li>
            </ul>
          </div>
        </div>
        <img src="../../assets/img/img_bw1.png" alt="" class="bw1" />
      </div>
      <div class="footer">
        <img src="../../assets/img/img_bw2.png" alt="" class="bw2" />
        <div class="info">
          <div class="top">
            <img src="../../assets/img/icon_mj@2x.png" alt="" />面积
          </div>
          <div class="data"><span class="num">130</span>平方公里</div>
        </div>
        <div class="info">
          <div class="top">
            <img src="../../assets/img/icon_rk@2x.png" alt="" />人口
          </div>
          <div class="data"><span class="num">90</span>万人</div>
        </div>
      </div>
    </div>
    <!-- 产业定位 -->
    <div id="panel2">
      <div class="w1200">
        <div class="panelTitle">产业定位</div>
        <div class="left_location">
          <img src="../../assets/img/img_cydw.jpg" alt="" />
        </div>
        <div class="right_location">
          <!-- 智能制造业 -->
          <div class="location_top">
            <div class="top_logo"></div>
            <div class="top_content">
              <div class="title">智能制造业</div>
              <div class="detail_content">
                以战略性新兴产业及高新技术产业为主线，以人才集聚和产业集聚为目标，着力引进高科技含量项目、高层次科技人才和高水平科技机构，打造科技与人才集聚的智能制造标杆园区。
              </div>
            </div>
            <div>
              <div class="item_logo log_1">应急安全</div>
              <div class="item_logo log_2">新材料</div>
              <div class="item_logo log_3">新能源汽车及配件</div>
              <div class="item_logo log_4">大数据</div>
            </div>
          </div>
          <div class="location_bottom">
            <div class="top_logo"></div>
            <div class="top_content">
              <div class="title">应急安全产业</div>
              <div class="detail_content">
                应急安全产业是国家规划重点布局的产业发展区域中，以安全产业为重点发展方向，具有示范、支撑、带动作用、特色鲜明的产业集聚、集群区域。
              </div>
            </div>
            <div>
              <div class="item_logo log_5">应急服务</div>
              <div class="item_logo log_6">应急制造</div>
              <div class="item_logo log_7">应急软件</div>
              <div class="item_logo log_8">应急产品经销</div>
            </div>
          </div>
          <div class="location_bottom">
            <div class="top_logo"></div>
            <div class="top_content">
              <div class="title">现代商贸服务业</div>
              <div class="detail_content">
                以“转型升级、提质增效”为主线，支持模式创新、跨界融合以及物联网、大数据等现代商业技术的运用，以电子商务、口岸商贸为引擎，着力引进体验式、复合式、仓储式的现代化大型商贸综合体，打造特色商品消费以及各类休闲项目。
              </div>
            </div>
            <div>
              <div class="item_logo log_1">口岸商贸</div>
              <div class="item_logo log_2">商务服务</div>
              <div class="item_logo log_3">金融服务</div>
              <div class="item_logo log_4">国际贸易</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="panel3" style="background: #fff">
      <div class="w1200" style="overflow: hidden">
        <div class="panelTitle">园区优势</div>
        <div class="content">
          <Accordion></Accordion>
        </div>
      </div>
    </div>
    <div id="panel8" style="background: #fff">
      <div class="w1200" style="overflow: hidden">
        <div class="panelTitle">高效审批</div>
        <div class="content fl-box">
          <div class="left-box">
            <div class="approve">
              <div class="approve-lable">当月审批数</div>
              <div class="approve-value">53</div>
            </div>
            <div class="approve">
              <div class="approve-lable">审批办结率</div>
              <div class="approve-value">81%</div>
            </div>
          </div>
          <div class="fl-1">
            <div class="approve-time">
              <div class="approve-time-title">审批时长排行榜</div>
              <div
                class="rank-item fl-box"
                v-for="(item, index) in 4"
                :key="index"
              >
                <div class="icon-no" :class="'icon-' + index">
                  {{ index + 1 }}
                </div>
                <div>浙江怀远电器有限公司</div>
                <div class="day">用时13天</div>
              </div>
            </div>
            <div class="approve-time">
              <div class="approve-time-title">平均审批时长</div>
              <div class="average-time">
                <div class="average-lable">项目立项阶段</div>
                <div class="fl-box">
                  <div class="time-progress">
                    <div class="progress" style="width: 50%"></div>
                  </div>
                  <div class="time">用时13天</div>
                </div>
              </div>
              <div class="average-time">
                <div class="average-lable">规划许可阶段</div>
                <div class="fl-box">
                  <div class="time-progress">
                    <div class="progress" style="width: 20%"></div>
                  </div>
                  <div class="time">用时3天</div>
                </div>
              </div>
              <div class="average-time">
                <div class="average-lable">施工许可阶段</div>
                <div class="fl-box">
                  <div class="time-progress">
                    <div class="progress" style="width: 30%"></div>
                  </div>
                  <div class="time">用时5天</div>
                </div>
              </div>
              <div class="average-time">
                <div class="average-lable">竣工验收阶段</div>
                <div class="fl-box">
                  <div class="time-progress">
                    <div class="progress" style="width: 10%"></div>
                  </div>
                  <div class="time">用时2天</div>
                </div>
              </div>
            </div>
            详情联系政务中心三楼黄琦：18368404872
          </div>
        </div>
      </div>
    </div>
    <div id="panel4" style="background: #fff">
      <div class="w1200">
        <div class="panelTitle">招商地图</div>
      </div>
      <div class="map">
        <div
          class="place place1"
          :class="{ active: placeIndex == 1 }"
          @click="placeIndex = 1"
        >
          1
        </div>
        <div
          class="place place2"
          :class="{ active: placeIndex == 2 }"
          @click="placeIndex = 2"
        >
          2
        </div>
        <div
          class="place place3"
          :class="{ active: placeIndex == 3 }"
          @click="placeIndex = 3"
        >
          3
        </div>
        <!-- <div class="place place4 blue-place" v-if="placeIndex != 4" @click="placeIndex = 4"></div>
        <div class="place place5 green-place" v-if="placeIndex != 5" @click="placeIndex = 5"></div>
        <div class="place place6 green-place" v-if="placeIndex != 6" @click="placeIndex = 6"></div> -->
        <!-- <div class="place-tip" :class="'place' + placeIndex">
          <img src="../../assets/img/ParkPromote/icon_xz.png" alt="" class="place-img" />
          战略性新兴产业
        </div> -->
        <div class="card">
          <template v-if="placeIndex != 1">
            <div class="card-title bb">滨海现代产业区</div>
            <div class="card-body">
              <div class="item-title">发展意向</div>
              <div class="industry-box">
                <div class="industry-title">战略性新兴产业</div>
                <div class="industry fl-c-b">
                  <div
                    class="industry-item fl-c-c"
                    v-for="item in industry"
                    :key="item.url"
                  >
                    {{ item }}
                  </div>
                </div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
              </div>
              <div class="item-title">开发主体</div>
              <div class="bb item-text">
                龙湾区人民政府、温州经济技术开发区管委会
              </div>
              <div class="item-title">规划范围</div>
              <div class="bb item-text">
                包括温州经济技术开发区滨海园区-民科基地及沙城、天河、海城、永兴等4个镇及街道。
              </div>
              <div class="item-title">企业风采</div>
              <el-carousel trigger="click" height="150px" class="card-banner">
                <el-carousel-item
                  v-for="(item, index) in cardBanner"
                  :key="index"
                  :style="`background: url(${item.url}) center no-repeat`"
                >
                  <div class="card-name">{{ item.name }}</div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </template>
          <template v-else>
            <div class="card-title bb">发展大楼</div>
            <div class="card-body">
              <el-carousel
                trigger="click"
                height="150px"
                class="swiper-container"
                arrow="always"
                ref="carousel"
                :interval="10000"
              >
                <!-- <el-carousel-item v-for="(item, index) in cardBanner" :key="index" >
                  <img src="../../assets/img/banner.jpg" draggable="false" class="card-item-img" />
                </el-carousel-item> -->
                <el-carousel-item
                  v-for="(item, index) in cardBanner"
                  :key="index"
                  :style="`background: url(${item.url}) center no-repeat`"
                >
                  <div class="card-name">{{ item.name }}</div>
                </el-carousel-item>
              </el-carousel>
              <div class="fl-c-b mb16">
                <img
                  src="../../assets/img/banner.jpg"
                  draggable="false"
                  class="banner-img fl-1"
                  @click="setActiveItem(0)"
                />
                <img
                  src="../../assets/img/banner1.jpg"
                  draggable="false"
                  class="banner-img fl-1"
                  @click="setActiveItem(1)"
                />
                <img
                  src="../../assets/img/banner2.jpg"
                  draggable="false"
                  class="banner-img fl-1"
                  @click="setActiveItem(2)"
                />
              </div>
              <div class="item-title mb16">基本信息</div>
              <div class="card-info-box fl-s-b">
                <div>
                  <div class="info-item">
                    <span class="info-label">地块名称：</span>{{ info.info1 }}
                  </div>
                  <div class="info-item">
                    <span class="info-label">供地面积：</span>{{ info.info2 }}
                  </div>
                  <div class="info-item">
                    <span class="info-label">用途：</span>{{ info.info3 }}
                  </div>
                  <div class="info-item">
                    <span class="info-label">出让方式：</span>{{ info.info4 }}
                  </div>
                  <!-- <div class="info-item"><span class="info-label">地块位置：</span>{{ info.info5 }}</div> -->
                  <!-- <div class="info-item"><span class="info-label">出让面积：</span>{{ info.info6 }}</div> -->
                  <div class="info-item">
                    <span class="info-label">容积率：</span>{{ info.info7 }}
                  </div>
                  <div class="info-item">
                    <span class="info-label">绿化率：</span> {{ info.info8 }}
                  </div>
                </div>
                <div
                  class="info-box"
                  :style="{
                    backgroundImage: `url(${require(`../../assets/img/approve/zsdt_img_jbxx.png`)}`,
                  }"
                ></div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div id="panel5" style="background: #fff">
      <div class="w1200">
        <div class="panelTitle">企业风采</div>
        <swiper
          class="Swipers"
          ref="Swipers"
          :options="swiperOptions"
          v-if="swiperList.length > 1"
          @slideChangeTransitionEnd="slideChangeEnd"
        >
          <swiper-slide
            class="swipersItem"
            v-for="item in swiperList"
            :key="item.name"
          >
            <template v-if="item.href">
              <a class="item" :href="item.href" target="open">
                <img :src="item.url" alt="" />
                <div class="name">{{ item.name }}</div>
              </a>
            </template>
            <template v-else>
              <div class="item">
                <img :src="item.url" alt="" />
                <div class="name">{{ item.name }}</div>
              </div>
            </template>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination">
          <img
            src="../../assets/img/arrow_right.png"
            draggable="false"
            alt=""
            class="arrow_left"
            @click="prev"
          />
          <div
            class="pagination-item"
            v-for="(item, i) in swiperList"
            :key="i"
            :class="index == i ? 'active' : ''"
          ></div>
          <img
            src="../../assets/img/arrow_right.png"
            draggable="false"
            alt=""
            class="arrow_right"
            @click="next"
          />
        </div>
        <div class="demand" @click="go('StaffNeedShow')">园区企业用工需求</div>
      </div>
    </div>
    <div id="panel6">
      <div class="w1200">
        <div class="panelTitle">领导关怀</div>
        <div class="content">
          <div class="left-leader">
            <img src="../../assets/img/xjp1.png" alt="" />
          </div>
          <div class="leader" style="text-align: center">
            <div class="item">
              <img src="../../assets/img/xjp2.png" alt="" />
              <div class="name">习近平同志<br />视察瓯江口</div>
            </div>
            <div class="item">
              <img src="../../assets/img/xjp3.png" alt="" />
              <div class="name">习近平同志<br />视察瓯江口</div>
            </div>
          </div>
          <div class="leader">
            <div class="item">
              <img src="../../assets/img/img_ldgh4.png" alt="" />
              <div class="name" style="padding-left: 13px">
                温州市委书记陈伟俊<br />听取瓯江口汇报
              </div>
            </div>
            <div class="item">
              <img src="../../assets/img/img_ldgh5.png" alt="" />
              <div class="name" style="padding-left: 13px">
                温州市市长姚高员<br />调研瓯江口
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="panel7" style="background: #fff">
      <div class="w1200">
        <div class="panelTitle">政策支持</div>
        <div class="content">
          <div class="policy" v-for="(item, i) in policy" :key="i">
            <div class="left">
              <div class="number">{{ i + 1 }}</div>
              <div class="name">政策</div>
            </div>
            <div class="line"></div>
            <div class="right">
              <a
                class="title"
                target="open"
                :href="attachmentUrl[2].attachmentUrl"
                >{{ item.title }}</a
              >
              <div class="info"></div>
              <div
                class="btn"
                v-if="item.annex"
                @click="handleOpenAnnex(item.annex)"
              >
                查看附件
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnchorMenu from "@/components/AnchorMenu";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Accordion from "./components/vue-accordion.vue";
import "swiper/swiper.scss";
import { mapState } from "vuex";
import { setToken } from '@/http/auth';
export default {
  name: "homePage",
  components: {
    AnchorMenu,
    Swiper,
    SwiperSlide,
    Accordion,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 20,
        observer: true,
        observeParents: true,
        loop: true,
        autoplay: true,
      },
      placeIndex: 1,
      index: 0,
      policy: [
        {
          title:
            "温州瓯江口产业集聚区管委会党政办公室关于印发《打造最优人才生态、促进人才优先发展的 27 条意见》的通知",
        },
        {
          title:
            "温州瓯江口产业集聚区管理委员会关于促进温州保税物流中心（B型）招商发展的若千意见（试行）",
        },
        {
          title:
            "温州瓯江口产业集聚区管理委员会关于扶持和发展跨境电子商务的若千意见(试行)",
        },
        {
          title:
            "温州瓯江口产业集聚区管理委员会关于印发《温州瓯江口产业集聚区进一步促进总部回归发展暂行办法》的通知",
        },
        {
          title: "温州瓯江口产业集聚区管理委员会关于促进企业上市工作的十条意见",
        },
        {
          title:
            "温州瓯江口产业集聚区管理委员会关于印发《温州瓯江口产业集聚区关于促进安全（应急）产业生态体系发展的实施意见（试行）》的通知",
        },
      ],
      anchorMenuData: [
        {
          link: "#panel1",
          name: "集聚区概况",
          icon: require("@/assets/img/icon_jjqgk.png"),
          iconActive: require("@/assets/img/icon_jjqgk-active.png"),
        },
        {
          link: "#panel2",
          name: "产业定位",
          icon: require("@/assets/img/icon_cydw_n.png"),
          iconActive: require("@/assets/img/icon_cydw_s.png"),
        },
        {
          link: "#panel3",
          name: "园区优势",
          icon: require("@/assets/img/icon_yqys_n.png"),
          iconActive: require("@/assets/img/icon_yqys_s.png"),
        },
        {
          link: "#panel8",
          name: "高效审批",
          icon: require("@/assets/img/approve/shenpi_icon_normal@2x.png"),
          iconActive: require("@/assets/img/approve/shenpi_icon_selected@2x.png"),
        },
        {
          link: "#panel4",
          name: "招商地图",
          icon: require("@/assets/img/icon_qwys.png"),
          iconActive: require("@/assets/img/icon_zsdt_n.png"),
        },
        {
          link: "#panel5",
          name: "企业风采",
          icon: require("@/assets/img/icon_qyfc_n.png"),
          iconActive: require("@/assets/img/icon_qyfc_s.png"),
        },
        {
          link: "#panel6",
          name: "领导关怀",
          icon: require("@/assets/img/icon_ldgh_n.png"),
          iconActive: require("@/assets/img/icon_ldgh_s.png"),
        },
        {
          link: "#panel7",
          name: "政策支持",
          icon: require("@/assets/img/icon_zczc_n.png"),
          iconActive: require("@/assets/img/icon_zczc_s.png"),
        },
      ],
      swiperData: [
        { url: require("@/assets/img/banner.jpg") },
        { url: require("@/assets/img/banner2.jpg") },
        { url: require("@/assets/img/banner3.jpg") },
        { url: require("@/assets/img/banner4.jpg") },
      ],
      swiperList: [
        {
          url: require("@/assets/img/qyfc/浙江瑞莱克斯医疗科技有限公司.jpg"),
          name: "浙江瑞莱克斯医疗科技有限公司",
          href: "www.reluex.com",
        },
        {
          url: require("@/assets/img/qyfc/一能电气有限公司.jpg"),
          name: "一能电气有限公司",
          href: "http://www.zynele.com/",
        },
        // { url: require('@/assets/img/img_gnptys4@2x.png'), name: '浙江源泰机电科技有限公司' },
        {
          url: require("@/assets/img/qyfc/mrd.jpg"),
          name: "浙江曼瑞德舒适系统有限公司",
          href: "http://www.menred.cn/",
        },
        {
          url: require("@/assets/img/qyfc/jmwj.jpeg"),
          name: "温州金马文具用品制造有限公司",
          href: "http://www.grasp1995.com/",
        },
        {
          url: require("@/assets/img/qyfc/温州新盟包装有限公司.png"),
          name: "温州新盟包装有限公司",
        },
        {
          url: require("@/assets/img/qyfc/威马汽车制造温州有限公司.png"),
          name: "威马汽车制造温州有限公司",
          href: "http://www.wm-motor.com/",
        },
        {
          url: require("@/assets/img/qyfc/浙江中亿豪科技有限公司.jpg"),
          name: "浙江中亿豪科技有限公司",
          href: "http://www.chinehow.com/",
        },
        {
          url: require("@/assets/img/qyfc/wpdx.png"),
          name: "温州网牌电线电缆有限公司",
        },
        // { url: require('@/assets/img/img_gnptys4@2x.png'), name: '温州市日冠科技有限公司' },
        {
          url: require("@/assets/img/qyfc/浙江而乐干燥设备有限公司.png"),
          name: "浙江而乐干燥设备有限公司",
          href: "http://www.erledrying.com/",
        },
        // { url: require('@/assets/img/img_gnptys4@2x.png'), name: '新光机械有限公司' },
        {
          url: require("@/assets/img/qyfc/温州宏丰特种材料有限公司.jpg"),
          name: "温州宏丰特种材料有限公司",
          href: "http://www.wzhf.com/main.asp",
        },
        {
          url: require("@/assets/img/qyfc/浙江凯威碳材料有限公司.png"),
          name: "浙江凯威碳材料有限公司",
          href: "http://www.wzhf.com/main.asp",
        },
        // { url: require('@/assets/img/img_gnptys4@2x.png'), name: '温州奔腾汽配有限公司' },
      ],
      industry: ["新能源", "新医药", "节能\n环保", "新材料", "电子\n信息"],
      cardBanner: [
        {
          name: "温州博弘电力有限公司",
          url: require("@/assets/img/banner.jpg"),
        },
        {
          name: "温州博弘电力有限公司",
          url: require("@/assets/img/banner1.jpg"),
        },
        {
          name: "温州博弘电力有限公司",
          url: require("@/assets/img/banner2.jpg"),
        },
        // { name: '温州博弘电力有限公司', url: require('@/assets/img/img_gnptys3@2x.png') },
      ],
      info: {
        info1: "发展大楼",
        info2: "48989平方米",
        info3: "商务金融用地",
        info4: "挂牌出让",
        info5: "温州市半岛起步区灵腾西路与霓翔南路交汇处",
        info6: "325.4公顷",
        info7: "2.86%",
        info8: "35%",
        info9: "166万元/公顷",
        info10: "100万元/公顷",
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.Swipers.$swiper;
    },
    ...mapState({
      attachmentUrl: (state) => state.attachmentUrl,
    }),
  },
  async created() {
    let res = await this.$api.findPolicySupportInfoPage();
    this.policy = res.rows.map((item) => ({
      title: item.POLICY_NAME,
      annex: `https://ojkcyzn.wenzhou.gov.cn:8093/attachment/${item.ATTACHMENT_URL}`,
    }));
    console.log("获取政策列表", res);
  },
  mounted() {
    let ticket = this.$route.query.ticket;
    if (ticket) {
      
      this.handleTicketLogin(ticket);
      console.log("获取url参数-ticket", ticket);
    }
  },
  methods: {
    async handleTicketLogin(ticket) {
      console.log("调用ticket", ticket);
      if(localStorage.getItem("LOGIN_DEBUG")){
        this.$message.success("调试模式 不调用登录")
        return;
      }
      let res = await this.$api.employeeLogin({ ticket });
      console.log("登录成功",res)
      if (res.success) {
        setToken(res.result.token);
        this.$message.success({ message: "登录成功", offset: 80 });
        this.centerDialogVisible = false;
        this.$api.getInfo().then((userInfo) => {
          console.log("用户信息获取成功",userInfo)
          if (userInfo.code === 2000) {
            if (userInfo.result) {
              this.$store.commit("updateValue", { userStore: res.userInfo });
            } else {
              this.$store.commit("updateValue", { userStore: null });
            }
          }
          // let a =  ;
          window.location.href =window.location.origin;
        }); //获取用户信息
      } else {
        console.error("Ticket登录失败",res)
        // this.$message.error({ message: res.message, offset: 80 });
      }
    },
    handleOpenAnnex(url) {
      window.open(url);
    },
    setActiveItem(i) {
      this.$refs.carousel.setActiveItem(i);
    },
    go(name) {
      this.$router.push({ name });
    },
    slideChangeEnd() {
      this.index = this.swiper.realIndex;
    },
    next() {
      this.swiper.slideNext();
    },
    prev() {
      this.swiper.slidePrev();
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .block .el-carousel__indicators--horizontal {
  left: 6.1%;
  bottom: 108px;
}
/deep/ .block .el-carousel__button {
  height: 6px;
  width: 18px;
  border-radius: 3px;
}
.panelTitle {
  width: 191px;
  height: 48px;
  font-family: MicrosoftYaHei-Bold;
  font-size: 36px;
  color: #135694;
  letter-spacing: 0.9px;
  text-align: right;
  margin-left: 625px;
  z-index: 2;
  position: relative;
  font-weight: 600;
}
.w1200 {
  // min-width: 1200px;
  width: 1343px;
  margin: 0 auto;
  position: relative;
}
.place-tip {
  position: absolute;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.56);
  padding-left: 54px;
  padding-right: 20px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #ffffff;
  .place-img {
    position: absolute;
    left: 0;
    bottom: 6px;
    width: 40px;
    height: 48px;
  }
}
#panel4 .map .place2.place-tip {
  left: 500px;
  padding-left: 20px;
  padding-right: 54px;
  .place-img {
    left: 100%;
    margin-left: -40px;
  }
}
#panel1 {
  width: 100%;
  margin: 0 auto;
  background-color: red;
  height: 769px;
  position: relative;
  .content {
    // width: calc(100% - 166px);
    min-width: 1200px;
    height: 696px;
    left: 50%;
    top: -6vw;
    transform: translate(-50%);
    position: absolute;
    z-index: 999;
    background: #ffffff;
    box-shadow: 0 12px 14px 0 rgba(10, 48, 85, 0.2);
    border-radius: 4px;
    border-radius: 4px;
    &::before {
      content: "SURVEY";
      position: absolute;
      font-family: MicrosoftYaHei-Bold;
      font-size: 86px;
      color: #f2f3f5;
      letter-spacing: 0;
      text-align: right;
      left: 136px;
      top: 44px;
      z-index: -99;
    }
    .title {
      margin-left: 314px;
      margin-top: 77px;
      font-family: MicrosoftYaHei-Bold;
      font-size: 36px;
      color: #135694;
      letter-spacing: 0.9px;
      line-height: 48px;
      font-weight: 600;
    }

    .line1-left {
      display: inline-block;
      height: 214px;
      width: 493px;
      margin-left: 135px;
      margin-top: 40px;
      .content_word {
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #333333;
        letter-spacing: 0;
        line-height: 34px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .look_detail {
        width: 171px;
        height: 48px;
        background: #135694;
        border-radius: 34px;
        margin-top: 10px;
        font-family: MicrosoftYaHei-Bold;
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 0.4px;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
      }
    }
    .line1-right {
      display: inline-block;
      margin-top: 53px;
      vertical-align: top;
      img {
        width: 512px;
        height: 214px;
        display: block;
      }
    }
    .line2-left {
      display: inline-block;
      vertical-align: top;
      margin-top: 18px;
      img {
        width: 502px;
        height: 266px;
        margin-left: 106px;
        display: block;
      }
    }
    .line2-right {
      display: inline-block;
      ul {
        margin: 90px 0 0 0;
        padding: 0;
        width: 512px;
        height: 131px;
        li {
          display: inline-block;
          line-height: 46px;
          margin-left: 36px;
          width: 200px;
          position: relative;

          &::before {
            content: "";
            display: block;
            width: 15px;
            position: absolute;
            left: -20px;
            top: 50%;
            transform: translateY(-50%);
            height: 18px;
            background: url("../../assets/img/icon_db@2x.png") center / cover
              no-repeat;
          }
        }
      }
    }
    .bw1 {
      width: 100%;
      height: 230px;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }
  .footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 256px;
    background: url("../../assets/img/img_yx1@2x.jpg");
    display: flex;
    justify-content: center;
    .bw2 {
      position: absolute;
      top: -116px;
      left: 0;
      height: 116px;
      width: 100%;
    }
    .info:nth-last-of-type(1) {
      margin-left: 283px;
    }
    .top {
      margin-top: 141px;
      display: flex;
      align-items: center;
      font-size: 24px;
      color: #ffffff;
      margin-bottom: 6px;
      img {
        width: 30px;
        height: 30px;
        margin-right: 4px;
      }
    }
    .data {
      display: flex;
      align-items: center;
      font-size: 24px;
      color: #ffffff;
      line-height: 31px;
      .num {
        font-size: 40px;
        color: #ffffff;
        line-height: 52px;
        margin-right: 8px;
      }
    }
  }
}
#panel2 {
  width: 100%;
  padding-top: 93px;
  background-color: #fff;
  padding-bottom: 60px;
  // height: 969px;
  .w1200 {
    &::before {
      content: "LOCATION";
      position: absolute;
      font-family: MicrosoftYaHei-Bold;
      font-size: 86px;
      color: #f2f3f5;
      letter-spacing: 0;
      text-align: right;
      left: 320px;
      top: -33px;
    }
  }
  .left_location {
    margin-left: 219px;
    margin-top: 74px;
    display: inline-block;
    vertical-align: top;
    img {
      width: 412px;
      height: 554px;
    }
  }
  .right_location {
    display: inline-block;
    margin-left: 49px;
    .location_top {
      height: 260px;
      margin-top: 73px;
      background-image: linear-gradient(270deg, #ffffff 0%, #f1f8ff 100%);
      .top_logo {
        width: 84px;
        height: 84px;
        margin-top: 59px;
        margin-left: 46px;
        display: inline-block;
        background: url("../../assets/img/icon_znzzy.png");
      }
      .top_content {
        display: inline-block;
        width: 432px;
        margin-top: 10px;
        margin-left: 30px;
        .title {
          font-family: MicrosoftYaHei-Bold;
          font-size: 16px;
          color: #000;
          letter-spacing: 0;
          line-height: 32px;
          font-weight: 600;
          margin-left: 0;
          &::before {
            content: "";
            display: inline-block;
            margin-right: 7px;
            width: 0;
            height: 0;
            border: 4px solid;
            border-color: transparent transparent transparent #000;
          }
        }
        .detail_content {
          font-family: MicrosoftYaHei-Bold;
          font-size: 16px;
          color: #333333;
          letter-spacing: 0;
          line-height: 32px;
          width: 432px;
        }
      }
      .item_logo {
        display: inline-block;
        margin: 54px 0 0 90px;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #024a8f;
        letter-spacing: 0;
        position: relative;
      }
      .log_1 {
        margin-left: 45px;
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          left: 0;
          top: -30px;
          width: 26px;
          height: 26px;
          background: url("../../assets/img/icon_yjaq@2x.png") center / cover
            no-repeat;
        }
      }
      .log_2 {
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          left: 0;
          top: -30px;
          width: 26px;
          height: 26px;
          background: url("../../assets/img/icon_xcl@2x.png") center / cover
            no-repeat;
        }
      }
      .log_3 {
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          left: 0;
          top: -30px;
          width: 26px;
          height: 26px;
          background: url("../../assets/img/icon_xnyqc@2x.png") center / cover
            no-repeat;
        }
      }
      .log_4 {
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          left: 0;
          top: -30px;
          width: 26px;
          height: 26px;
          background: url("../../assets/img/icon_dsj@2x.png") center / cover
            no-repeat;
        }
      }
    }
    .location_bottom {
      background-image: linear-gradient(270deg, #ffffff 0%, #f1f8ff 100%);
      height: 260px;
      margin-top: 34px;
      .top_logo {
        width: 84px;
        height: 84px;
        margin-top: 59px;
        margin-left: 46px;
        display: inline-block;
        background: url("../../assets/img/icon_xdsmfzy@2x.png") center / cover
          no-repeat;
      }
      .top_content {
        margin-top: 10px;
        display: inline-block;
        width: 432px;
        margin-left: 30px;
        .title {
          font-family: MicrosoftYaHei-Bold;
          font-size: 16px;
          color: #000;
          letter-spacing: 0;
          line-height: 32px;
          font-weight: 600;
          margin-left: 0;
          &::before {
            content: "";
            display: inline-block;
            margin-right: 7px;
            width: 0;
            height: 0;
            border: 4px solid;
            border-color: transparent transparent transparent #000;
          }
        }
        .detail_content {
          font-family: MicrosoftYaHei-Bold;
          font-size: 16px;
          color: #333333;
          letter-spacing: 0;
          line-height: 32px;
          width: 432px;
        }
      }
      .item_logo {
        display: inline-block;
        margin: 54px 0 0 106px;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #024a8f;
        letter-spacing: 0;
        position: relative;
      }
      .log_1 {
        margin-left: 45px;
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          left: 0;
          top: -30px;
          width: 26px;
          height: 26px;
          background: url("../../assets/img/icon_kasm@2x.png") center / cover
            no-repeat;
        }
      }
      .log_2 {
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          left: 0;
          top: -30px;
          width: 26px;
          height: 26px;
          background: url("../../assets/img/icon_swfw@2x.png") center / cover
            no-repeat;
        }
      }
      .log_3 {
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          left: 0;
          top: -30px;
          width: 26px;
          height: 26px;
          background: url("../../assets/img/icon_jrfw@2x.png") center / cover
            no-repeat;
        }
      }
      .log_4 {
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          left: 0;
          top: -30px;
          width: 26px;
          height: 26px;
          background: url("../../assets/img/icon_gjmy@2x.png") center / cover
            no-repeat;
        }
      }
      .log_5 {
        margin-left: 45px;
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          left: 0;
          top: -30px;
          width: 26px;
          height: 26px;
          background: url("../../assets/img/icon_swfw@2x.png") center / cover
            no-repeat;
        }
      }
      .log_6 {
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          left: 0;
          top: -30px;
          width: 26px;
          height: 26px;
          background: url("../../assets/img/icon_xcl@2x.png") center / cover
            no-repeat;
        }
      }
      .log_7 {
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          left: 0;
          top: -30px;
          width: 26px;
          height: 26px;
          background: url("../../assets/img/icon_dsj@2x.png") center / cover
            no-repeat;
        }
      }
      .log_8 {
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          left: 0;
          top: -30px;
          width: 26px;
          height: 26px;
          background: url("../../assets/img/icon_gjmy@2x.png") center / cover
            no-repeat;
        }
      }
    }
  }
}
#panel3 {
  width: 100%;
  // height: 675px;
  // padding-top: 93px;
  position: relative;
  overflow: hidden;
  padding-bottom: 60px;
  // height: 703px;
  .panelTitle {
    margin-top: 33px;
  }
  .w1200 {
    &::before {
      content: "ADVANTAGE";
      position: absolute;
      font-family: MicrosoftYaHei-Bold;
      font-size: 86px;
      color: #f2f3f5;
      letter-spacing: 0;
      text-align: right;
      left: 220px;
      top: 0;
    }
  }
  .content {
    margin-left: 188px;
    margin-top: 71px;
    height: 556px;
  }
}
#panel4 {
  width: 100%;
  overflow: hidden;
  position: relative;
  // padding-top: 93px;
  .w1200 {
    &::before {
      content: "MAP";
      position: absolute;
      font-family: MicrosoftYaHei-Bold;
      font-size: 86px;
      color: #f2f3f5;
      letter-spacing: 0;
      text-align: right;
      left: 569px;
      top: -35px;
    }
  }
  .panelTitle {
    margin-top: 33px;
    margin-bottom: 53px;
  }
  .map {
    width: 1200px;
    height: 725px;
    background: url(../../assets/img/approve/zsdt_img_map@2x.png) center
      no-repeat;
    background-size: 100%;
    margin: 0 auto;
    position: relative;
    .place {
      width: 42px;
      height: 48px;
      font-size: 16px;
      color: #ffffff;
      line-height: 44px;
      text-align: center;
      background: url(../../assets/img/approve/zsdt_icon_location-normal.png)
        center no-repeat;
      // border-radius: 50%;
      // border: 3px solid #fff;
      position: absolute;
      cursor: pointer;
      &.active,
      &:hover {
        background: url(../../assets/img/approve/zsdt_icon_location-selected.png)
          center no-repeat;
      }
    }
    .place1 {
      left: 363px;
      top: 195px;
    }
    .place2 {
      top: 188px;
      left: 481px;
    }
    .place3 {
      left: 586px;
      top: 288px;
    }
    .place4 {
      left: 610px;
      top: 400px;
    }
    .place5 {
      left: 570px;
      top: 312px;
    }
    .place6 {
      left: 502px;
      top: 405px;
    }
    // .red-place {
    //   background: #ef5350;
    // }
    // .green-place {
    //   background: #64c000;
    // }
    // .blue-place {
    //   background: #3381e2;
    // }
    .card {
      position: absolute;
      top: 50%;
      right: 36px;
      width: 380px;
      // height: 644px;
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      background: #ffffff;
      border-radius: 4px;
      transform: translateY(-50%);
      .card-title {
        font-size: 16px;
        color: #000000;
        font-weight: 600;
        line-height: 50px;
        padding-left: 20px;
        margin-bottom: 9px;
      }
      .card-body {
        padding: 0 20px 20px;
        .item-title {
          color: #000000;
          position: relative;
          padding-left: 6px;
          &::after {
            content: "";
            position: absolute;
            top: 3px;
            left: 0;
            width: 2px;
            height: 14px;
            background: #0e459c;
          }
        }
        .item-text {
          margin-top: 8px;
          padding-bottom: 17.5px;
          margin-bottom: 17.5px;
          padding-left: 6px;
        }
        .card-banner {
          margin-top: 18px;
        }
        .card-item-img {
          width: 100%;
        }
        .el-carousel__item {
          background-size: 100% !important;
        }
        /deep/ .el-carousel__arrow {
          background-color: rgba(0, 0, 0, 0.6);
        }
        .swiper-container {
          margin-bottom: 8px;
        }
        .banner-img {
          width: 108px;
          height: 60px;
          display: block;
          cursor: pointer;
        }
        .banner-img:nth-of-type(2) {
          margin: 0 8px;
        }
        .card-name {
          font-size: 14px;
          color: #ffffff;
          text-align: center;
          margin-top: 12px;
          font-weight: 600;
        }
        .industry-box {
          background-image: linear-gradient(180deg, #f6faff 0%, #e9f4ff 100%);
          border-radius: 0 0 4px 4px;
          margin: 18px 0;
          height: 130px;
          border-top: 1px solid #4393f8;
          position: relative;
          text-align: center;
          .industry-title {
            position: absolute;
            top: 0;
            left: 94px;
            width: 158px;
            height: 35px;
            line-height: 35px;
            color: #ffffff;
            background: url(../../assets/img/ParkPromote/wzxq_box.png) center
              no-repeat;
          }
        }
        .industry {
          height: 46px;
          margin: 56px 23px 0;
          overflow: hidden;
          position: relative;
          .industry-item {
            width: 46px;
            height: 46px;
            background: #4393f8;
            border-radius: 50%;
            font-size: 12px;
            color: #ffffff;
            font-weight: 600;
            line-height: 16px;
            white-space: pre-line;
          }
        }
        .swiper-button-prev {
          position: absolute;
          top: 61px;
          left: 0;
          margin: 0;
          width: 20px;
          height: 36px;
          cursor: pointer;
          background: url(../../assets/img/ParkPromote/yx_arrow_left_n.png)
            center no-repeat;
          &:hover {
            background: url(../../assets/img/ParkPromote/yx_arrow_left_s.png)
              center no-repeat;
          }
        }
        .swiper-button-next {
          position: absolute;
          top: 61px;
          right: 0;
          margin: 0;
          width: 20px;
          height: 36px;
          cursor: pointer;
          background: url(../../assets/img/ParkPromote/yx_arrow_right_n.png)
            center no-repeat;
          &:hover {
            background: url(../../assets/img/ParkPromote/yx_arrow_right_s.png)
              center no-repeat;
          }
        }

        .card-info-box {
          background: #f5f5f5;
          padding: 16px;
        }
        .info-box {
          width: 120px;
          height: 120px;
          background-repeat: no-repeat;
          background-position: center;
        }
        .info-item {
          font-size: 14px;
          color: #2a2a2a;
          margin-bottom: 10px;
          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }
        }

        .info-item .info-label {
          color: #333;
          width: 70px;
          display: inline-block;
        }
      }
    }
  }
}
#panel5 {
  width: 100%;
  padding-top: 93px;
  overflow: hidden;
  padding-bottom: 60px;
  .w1200 {
    &::before {
      content: "ENTERPRISE";
      position: absolute;
      font-family: MicrosoftYaHei-Bold;
      font-size: 86px;
      color: #f2f3f5;
      letter-spacing: 0;
      text-align: right;
      left: 262px;
      top: -35px;
    }
  }
  .panelTitle {
    margin-bottom: 61px;
  }
  .swiper-pagination {
    position: absolute;
    right: 70px;
    top: 75px;
    display: flex;
    align-items: center;
    .pagination-item {
      background: #cccccc;
      border-radius: 2px;
      height: 4px;
      width: 18px;
      margin-right: 2px;
      transition: all 0.5s;
    }
    .arrow_right {
      width: 24px;
      height: 24px;
      margin-left: 18px;
      cursor: pointer;
    }
    .arrow_left {
      width: 24px;
      height: 24px;
      margin-right: 18px;
      cursor: pointer;
      transform: rotate(180deg);
    }
    .active {
      background: #4192f7;
      width: 29px;
    }
  }
  .Swipers {
    width: 1060px;
    margin-left: 219px;
    .swipersItem {
      width: 250px;
      height: 218px;
      border: 1px solid #d8d8d8;
      padding: 14px;
      box-sizing: border-box;
      cursor: pointer;
      a {
        text-decoration: none;
      }
      img {
        width: 222px;
        height: 156px;
        display: block;
      }
      .name {
        text-align: center;
        margin-top: 14px;
        font-size: 14px;
        color: #333333;
        font-weight: 600;
      }
    }
  }
  .demand {
    border: 1px solid #135694;
    border-radius: 4px;
    padding: 6px 15px;
    cursor: pointer;
    font-size: 12px;
    color: #135694;
    line-height: 16px;
    display: inline-block;
    // width: 96px;
    margin-left: 686px;
    margin-top: 28px;
    margin-bottom: 30px;
  }
}
#panel6 {
  width: 100%;
  height: 500px;
  padding-top: 63px;
  background: url("../../assets/img/img_yx2@2x.jpg") center no-repeat;
  .w1200 {
    &::before {
      content: "LEADER";
      opacity: 0.16;
      position: absolute;
      font-family: MicrosoftYaHei-Bold;
      font-size: 86px;
      color: #f2f3f5;
      letter-spacing: 0;
      text-align: right;
      left: 433px;
      top: -35px;
    }
  }
  .panelTitle {
    color: #ffffff;
  }
  .content {
    width: 1065px;
    display: flex;
    margin-top: 61px;
    margin-left: 218px;
    height: 288px;
    .left-leader {
      width: 343px;
      height: 288px;
      background-color: #fff;
      img {
        width: 100%;
        height: 100%;
        display: block;
        transition: all 0.3s;
      }
      img:hover {
        transform: scale(1.3);
      }
    }

    .leader {
      width: 341px;
      margin-left: 20px;
      .item {
        height: 136px;
        display: flex;
        align-items: center;
        .name {
          height: 100%;
          font-weight: 600;
          padding: 46px 0;
          box-sizing: border-box;
          flex: 1;
          font-size: 14px;
          color: #fff;
          line-height: 22px;

          background-image: linear-gradient(
            90deg,
            rgba(19, 86, 148, 0) 56%,
            #0a2d52 100%
          );
        }
        img {
          width: 190px;
          height: 136px;
          transition: all 0.3s;
        }
        img:hover {
          transform: scale(1.3);
        }
      }
      .item:nth-last-of-type(1) {
        margin-top: 16px;
      }
    }
  }
}
#panel7 {
  width: 100%;
  padding-top: 73px;
  height: 620px;
  .w1200 {
    &::before {
      content: "POLICY";
      position: absolute;
      font-family: MicrosoftYaHei-Bold;
      font-size: 86px;
      color: #f2f3f5;
      letter-spacing: 0;
      text-align: right;
      left: 456px;
      top: -35px;
    }
  }
  .content {
    margin: 55px 38px 0 83px;
    display: flex;
    flex-wrap: wrap;
    .policy {
      display: flex;
      margin-bottom: 56px;
      // padding-left: 86px;
      padding: 21px 45px 21px 28px;
      .left {
        margin-right: 20px;
        .number {
          font-size: 30px;
          color: #999999;
          line-height: 40px;
          text-align: center;
        }
        .name {
          font-size: 16px;
          color: #999999;
          line-height: 21px;
        }
      }

      .line {
        background-color: #dddddd;
        width: 1px;
        height: 118px;
        margin-top: 5px;
      }
      .right {
        margin-left: 15px;
        width: 228px;
        .title {
          font-size: 14px;
          color: #666666;
          line-height: 19px;
          height: 38px;
          margin-bottom: 10px;
          font-weight: 600;
          display: block;
          text-decoration: none;
        }
        .info {
          line-height: 16px;
          height: 32px;
          font-size: 12px;
          color: #666666;
          margin-bottom: 16px;
        }
        .btn {
          background: #135694;
          border-radius: 34px;
          width: 100px;
          height: 28px;
          text-align: center;
          line-height: 28px;
          font-size: 12px;
          color: #ffffff;
          letter-spacing: 0.3px;
          cursor: pointer;
        }
      }
    }
    .policy:nth-of-type(3n + 1) {
      // padding-left: 116px;
      margin-left: 115px;
    }
    .policy:hover {
      background-image: linear-gradient(180deg, #fafcfe 0%, #eaf2f9 100%);
    }
  }
}
#panel8 {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 60px;
  .panelTitle {
    margin-top: 33px;
  }
  .w1200 {
    &::before {
      content: "APPROVE";
      position: absolute;
      font-family: MicrosoftYaHei-Bold;
      font-size: 86px;
      color: #f2f3f5;
      letter-spacing: 0;
      text-align: right;
      left: 365px;
      top: 0;
    }
  }
  .content {
    margin-left: 166px;
    margin-top: 63px;
    height: 490px;
    .left-box {
      margin-right: 50px;
      width: 700px;
      height: 100%;
      background: url("../../assets/img/approve/gxsp_img_normal.png");
      display: flex;
      justify-content: center;
      text-align: center;
      .approve-lable {
        margin-top: 45px;
        font-size: 16px;
        color: #81c8f1;
        font-weight: 600;
        margin-bottom: 7px;
      }
      .approve-value {
        font-family: LetsgoDigital-Regular;
        font-size: 48px;
        color: #ffffff;
      }
      .approve:nth-of-type(1) {
        margin-right: 149px;
      }
    }
    .approve-time {
      background-image: linear-gradient(270deg, #ffffff 0%, #f1f8ff 100%);
      padding: 18px;
      .approve-time-title {
        font-size: 16px;
        color: #333333;
        font-weight: 600;
        margin-bottom: 16px;
      }
      &:nth-of-type(1) {
        margin-bottom: 16px;
      }
      .rank-item {
        line-height: 20px;
        // margin-bottom: 10px;
        text-align: center;
        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }
        .icon-no {
          width: 20px;
          margin-right: 14px;
          font-size: 12px;
          color: #fff;
          background: #024a8f;
          // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
          border-radius: 50%;
          border: 2px solid #6384de;
          line-height: 16px;
          height: 20px;
        }
        .icon-0 {
          width: 40px;
          height: 45px;
          background: url("../../assets/img/approve/sp_icon_number1@2x.png")
            center center no-repeat;
          background-size: 100%;
          border-radius: unset;
          border: none;
          line-height: 42px;
          margin-left: -10px;
          margin-top: -10px;
          margin-right: 4px;
        }
        .icon-1 {
          height: 45px;
          width: 40px;
          background: url("../../assets/img/approve/sp_icon_number2@2x.png")
            center center no-repeat;
          border-radius: unset;
          background-size: 100%;
          border: none;
          line-height: 42px;
          margin-left: -10px;
          margin-top: -10px;
          margin-right: 4px;
        }
        .icon-2 {
          width: 40px;
          height: 45px;
          background: url("../../assets/img/approve/sp_icon_number3@2x.png")
            center center no-repeat;
          border-radius: unset;
          border: none;
          background-size: 100%;
          line-height: 42px;
          margin-left: -10px;
          margin-top: -10px;
          margin-right: 4px;
        }
        .day {
          background: #e4f4ff;
          border-radius: 10px;
          font-size: 12px;
          color: #024a8f;
          width: 70px;
          margin-left: 25px;
          height: 20px;
        }
      }
      .average-time {
        font-size: 12px;
        color: #666666;
        margin-top: 16px;
        .average-lable {
          margin-bottom: 2px;
        }
        .time-progress {
          width: 200px;
          height: 10px;
          background-color: rgba(32, 100, 165, 0.2);
          margin-right: 10px;
          margin-top: 6px;
          .progress {
            height: 100%;
            background: #2064a5;
          }
        }
      }
    }
  }
}

</style>
