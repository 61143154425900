<template>
  <li :class="{ active: num == 2 }" @mouseover="active(2)" @mouseleave="active(0)">
    <div style="width: 615px; height: 556px">
      <div class="left">
        <img src="../../../assets/img/icon_fwys.png" draggable="false" alt="" class="icon" />
        <div class="title">服务优势</div>
        <div class="line"></div>
        <div class="info">
          瓯江口产业集聚区作为温州市政府派出机构，自主负责核心区域规划、用地和开发建设管理工作，经济发展、自然资源与规划等相关部门一应俱全，以“审批不出区、最多跑一次”的综合审批服务体制为工作宗旨，营造了便捷、高效的政府服务体系和重商、亲商的服务环境。
        </div>
        <img src="../../../assets/img/icon_zk.png" draggable="false" alt="" class="arrow" v-if="num != 2" />
      </div>
      <div class="right">
        <div class="title">工作宗旨</div>
        <div class="text">“审批不出区、最多跑一次”的综合审批服务体制</div>
        <div class="title">招商标准</div>
        <div class="text">创新、集约、安全、清洁“的可持续发展能力</div>
        <div class="fl-box">
          <div class="item">
            <img src="../../../assets/img/img_fwys1.png" draggable="false" alt="" />
            <div class="info">
              一带一主题<br />
              一路一特色
            </div>
          </div>
          <div class="item">
            <img src="../../../assets/img/img_fwys2.png" draggable="false" alt="" />
            <div class="info">
              三带<br />
              九园
            </div>
          </div>
          <div class="item">
            <img src="../../../assets/img/img_fwys3.png" draggable="false" alt="" />
            <div class="info">
              多彩绿带<br />
              花漫新城
            </div>
          </div>
          <div class="item">
            <img src="../../../assets/img/img_fwys4.png" draggable="false" alt="" />
            <div class="info">
              特色景观大道<br />
              精品主题公园
            </div>
          </div>
        </div>
        <div class="title">政务服务中心</div>
        <img src="../../../assets/img/img_fwys5.jpg" draggable="false" alt="" class="img_fwys5" />
      </div>
    </div>
  </li>
</template>
<script>
export default {
  props: ['num'],
  methods: {
    active(num) {
      this.$emit('active', num);
    },
  },
};
</script>
<style lang='scss' scoped>
li {
  width: 160px;
  overflow: hidden;
  float: left;
  transition: all 500ms;
}
.left {
  width: 160px;
  height: 100%;
  float: left;
  transition: all 500ms;
  background-image: linear-gradient(98deg, #3a77af 0%, #135694 99%);
  position: relative;
  .icon {
    margin: 114px auto 16px;
    width: 60px;
    height: 60px;
    display: block;
  }
  .title {
    font-size: 14px;
    color: #ffffff;
    line-height: 19px;
    text-align: center;
    margin-bottom: 8px;
    font-weight: 600;
  }
  .line {
    opacity: 0.36;
    background: #ffffff;
    border-radius: 3px;
    margin: 0 auto;
  }
  .info {
    opacity: 0.8;
    font-size: 12px;
    color: #ffffff;
    line-height: 22px;
    width: 116px;
    margin: 6px auto 0;
  }
  .arrow {
    width: 20px;
    height: 36px;
    position: absolute;
    right: 0;
    top: 260px;
  }
}
.right {
  width: 399px;
  height: 100%;
  float: left;
  padding-top: 22px;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  box-sizing: border-box;
  padding-left: 26px;
  .title {
    font-size: 14px;
    color: #135694;
    font-weight: 600;
    line-height: 16px;
    border-left: 4px solid #135694;
    padding-left: 10px;
    margin-bottom: 11px;
  }
  .text {
    margin-top: 11px;
    margin-left: 14px;
    font-size: 12px;
    color: #666666;
    line-height: 16px;
    margin-bottom: 26px;
  }
  
  .item {
    margin-right: 10px;
    margin-bottom: 52px;
    img {
      width: 80px;
      height: 80px;
      display: block;
    }
    .info {
      font-size: 12px;
      color: #0b5193;
      line-height: 16px;
      margin-top: 10px;
      text-align: center;
    }
  }
  .img_fwys5 {
    margin-top: 17px;
    width: 354px;
    height: 160px;
    display: block;
  }
}
</style>
