<template>
  <li :class="{ active: num == 1 }" @mouseover="active(1)" @mouseleave="active(0)">
    <div style="width: 615px; height: 556px">
      <div class="left">
        <img src="../../../assets/img/icon_qwys.png" draggable="false" alt="" class="icon" />
        <div class="title">区域优势</div>
        <div class="line"></div>
        <div class="info">
          瓯江口产业集聚区位于温州产业沿海发展轴和城市沿江发展轴的“两轴交汇处”，南面毗邻龙湾国际机场和规划中的温州高铁东站、东边为状元岙深水巷区。区内330国道、228国道、沈海高速复线、S1、S2轨道交通贯穿其间，区间快速路与温州主城区高效连接，构建了完整的城市交通网络。
        </div>
        <img src="../../../assets/img/icon_zk.png" draggable="false" alt="" class="arrow" v-if="num != 1" />
      </div>
      <div class="right">
        <div class="title">区域地图</div>
        <div class="content">
          <img src="../../../assets/img/img_qwys1.png" draggable="false" alt="" class="qwysImg" />
          <div class="position">
            <div class="item">
              <div class="icons">
                <img src="../../../assets/img/img_qwys_n.png" draggable="false" alt="" class="compass" />
                <div>北 / N</div>
              </div>
              <div class="">
                <div class="name">乐清市</div>
                <div class="info">经济活力进发的城市</div>
              </div>
            </div>
            <div class="item">
              <div class="icons">
                <img src="../../../assets/img/img_qwys_s.png" draggable="false" alt="" class="compass" />
                <div>南 / S</div>
              </div>
              <div class="">
                <div class="name">龙湾国际机场</div>
                <div class="info">直线距离3公里</div>
              </div>
            </div>
            <div class="item">
              <div class="icons">
                <img src="../../../assets/img/img_qwys_w.png" draggable="false" alt="" class="compass" />
                <div>西 / W</div>
              </div>
              <div class="">
                <div class="name">浙南产业集聚区</div>
                <div class="info">多领域完善产业链</div>
              </div>
            </div>
            <div class="item">
              <div class="icons">
                <img src="../../../assets/img/img_qwys_e.png" draggable="false" alt="" class="compass" />
                <div>东 / E</div>
              </div>
              <div class="">
                <div class="name">状元岙深水港</div>
                <div class="info">距离15公里</div>
              </div>
            </div>
          </div>
        </div>
        <img src="../../../assets/img/img_qwys2.jpg" alt="" draggable="false" class="map" />
      </div>
    </div>
  </li>
</template>
<script>
export default {
  props: ['num'],
  methods: {
    active(num) {
      this.$emit('active', num);
    },
  },
};
</script>
<style lang='scss' scoped>
li {
  width: 160px;
  overflow: hidden;
  float: left;
  transition: all 500ms;
}
.left {
  width: 160px;
  height: 100%;
  float: left;
  transition: all 500ms;
  background-image: linear-gradient(98deg, #3a77af 0%, #135694 99%);
  position: relative;
  .icon {
    margin: 114px auto 16px;
    width: 60px;
    height: 60px;
    display: block;
  }
  .title {
    font-size: 14px;
    color: #ffffff;
    line-height: 19px;
    text-align: center;
    margin-bottom: 8px;
    font-weight: 600;
  }
  .line {
    opacity: 0.36;
    background: #ffffff;
    border-radius: 3px;
    margin: 0 auto;
  }
  .info {
    opacity: 0.8;
    font-size: 12px;
    color: #ffffff;
    line-height: 22px;
    width: 116px;
    margin: 6px auto 0;
  }
  .arrow {
    width: 20px;
    height: 36px;
    position: absolute;
    right: 0;
    top: 260px;
  }
}
.right {
  width: 399px;
  height: 100%;
  float: left;
  padding-top: 22px;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  box-sizing: border-box;
  .title {
    font-size: 14px;
    color: #135694;
    font-weight: 600;
    line-height: 16px;
    border-left: 4px solid #135694;
    padding-left: 10px;
    margin-left: 26px;
    margin-bottom: 17px;
  }
  .content {
    margin-left: 26px;
    display: flex;
    .qwysImg {
      width: 177px;
      height: 282px;
      display: block;
    }
    .position {
      margin-left: 15px;
      .item {
        display: flex;
        margin-top: 26px;
        .icons {
          margin-right: 13px;

          .compass {
            width: 23px;
            height: 26px;
            margin-bottom: 6px;
            display: block;
          }
          div {
            font-size: 12px;
            color: #333333;
            line-height: 16px;
          }
        }
        .name {
          font-size: 14px;
          color: #333333;
          font-weight: 600;
          margin-bottom: 9px;
          margin-top: 5px;
        }
        .info {
          font-size: 12px;
          color: #666666;
          line-height: 15px;
        }
      }
      .item:nth-of-type(1) {
        margin-top: 12px;
      }
    }
  }
  .map {
    margin-top: 31px;
    width: 354px;
    height: 160px;
    margin-left: 26px;
    display: block;
  }
}
</style>
